/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_UNI_USER = "LOGIN_UNI_USER";
export const LOGIN_UNI_USER_SUCCESS = "LOGIN_UNI_USER_SUCCESS";
export const LOGIN_UNI_USER_ERROR = "LOGIN_UNI_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const RESET_USER = "RESET_USER";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";
export const RESET_USER_ERROR = "RESET_USER_ERROR";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";
export const UNI_FORGET_PASSWORD = "UNI_FORGET_PASSWORD"
export const UNI_FORGET_PASSWORD_SUCCESS = "UNI_FORGET_PASSWORD_SUCCESS"
export const UNI_FORGET_PASSWORD_ERROR = "UNI_FORGET_PASSWORD_ERROR"
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";
export const OTP_RESEND = "OTP_RESEND";
export const OTP_RESEND_SUCCESS = "OTP_RESEND_SUCCESS";
export const OTP_RESEND_ERROR = "OTP_RESEND_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SEND_MAGICLINK = "SEND_MAGICLINK";
export const SEND_MAGICLINK_SUCCESS = "SEND_MAGICLINK_SUCCESS";
export const SEND_MAGICLINK_ERROR = "SEND_MAGICLINK_ERROR";
export const MAGIC_LINK_VERIFY = "MAGIC_LINK_VERIFY";
export const MAGIC_LINK_VERIFY_SUCCESS = "MAGIC_LINK_VERIFY_SUCCESS";
export const MAGIC_LINK_VERIFY_ERROR = "MAGIC_LINK_VERIFY_ERROR";

export const VERIFY_EMAIL_LINK = "VERIFY_EMAIL_LINK";
export const VERIFY_EMAIL_LINK_SUCCESS = "VERIFY_EMAIL_LINK_SUCCESS";
export const VERIFY_EMAIL_LINK_ERROR = "VERIFY_EMAIL_LINK_ERROR";

export const VERIFY_AUTHORIZATION = "VERIFY_AUTHORIZATION";
export const VERIFY_AUTHORIZATION_SUCCESS = "VERIFY_AUTHORIZATION_SUCCESS";
export const VERIFY_AUTHORIZATION_ERROR = "VERIFY_AUTHORIZATION_ERROR";

export const VERIFY_MOBILE = "VERIFY_MOBILE";
export const VERIFY_MOBILE_ERROR = "VERIFY_MOBILE_ERROR";
export const VERIFY_MOBILE_SUCCESS = "VERIFY_MOBILE_SUCCESS";
export const VERIFY_MOBILE_OTP = "VERIFY_MOBILE_OTP";
export const VERIFY_MOBILE_OTP_ERROR = "VERIFY_MOBILE_OTP_ERROR";
export const VERIFY_MOBILE_OTP_SUCCESS = "VERIFY_MOBILE_OTP_SUCCESS";
// Student
export const ADD_CASE = "ADD_CASE";
export const ADD_CASE_SUCCESS = "ADD_CASE_SUCCESS";
export const ADD_CASE_ERROR = "ADD_CASE_ERROR";

export const ADD_ENROLMENT = "ADD_ENROLMENT";
export const ADD_ENROLMENT_SUCCESS = "ADD_ENROLMENT_SUCCESS";
export const ADD_ENROLMENT_ERROR = "ADD_ENROLMENT_ERROR";

export const ADD_QUICK_ENROLMENT = "ADD_QUICK_ENROLMENT";
export const ADD_QUICK_ENROLMENT_SUCCESS = "ADD_QUICK_ENROLMENT_SUCCESS";
export const ADD_QUICK_ENROLMENT_ERROR = "ADD_QUICK_ENROLMENT_ERROR";

export const GET_EXPERIENCE = "GET_EXPERIENCE";
export const GET_EXPERIENCE_SUCCESS = "GET_EXPERIENCE_SUCCESS";
export const GET_EXPERIENCE_ERROR = "GET_EXPERIENCE_ERROR";

// PRODUCT DETAILS
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";

export const GET_PAYMENT_SOURCES = "GET_PRODUPAYMENT_SOURCES";
export const GET_PAYMENT_SOURCES_SUCCESS = "GET_PAYMENT_SOURCES_SUCCESS";
export const GET_PAYMENT_SOURCES_ERROR = "GET_PAYMENT_SOURCES_ERROR";

export const GET_BACKEND_USERS = "GET_BACKEND_USERS";
export const GET_BACKEND_USERS_SUCCESS = "GET_BACKEND_USERS_SUCCESS";
export const GET_BACKEND_USERS_ERROR = "GET_BACKEND_USERS_ERROR";

export const GET_SCORES = "GET_SCORES";
export const GET_SCORES_SUCCESS = "GET_SCORES_SUCCESS";
export const GET_SCORES_ERROR = "GET_SCORES_ERROR";

export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_ERROR = "GET_SCHOOLS_ERROR";

export const GET_History = "GET_History";
export const GET_History_SUCCESS = "GET_History_SUCCESS";
export const GET_History_ERROR = "GET_History_ERROR";

export const CUSTOM_ERROR = "CUSTOM_ERROR";
export const ADD_NOTES = "ADD_NOTES";
export const ADD_NOTES_SUCCESS = "ADD_NOTES_SUCCESS";
export const ADD_NOTES_ERROR = "ADD_NOTES_ERROR";

/* Details*/
export const GET_STUDENT_DETAILS = "GET_STUDENT";
export const GET_STUDENT_DETAILS_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_DETAILS_ERROR = "TODO_GET_ERROR";

// PRODUCT CHECKOUT
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_ERROR = "GET_PRODUCT_DETAILS_ERROR";

export const GET_COUPON_DETAILS = "GET_COUPON_DETAILS";
export const GET_COUPON_DETAILS_SUCCESS = "GET_COUPON_DETAILS_SUCCESS";
export const GET_COUPON_DETAILS_ERROR = "GET_COUPON_DETAILS_ERROR";

export const GET_PRODUCT_COUPON = "GET_PRODUCT_COUPON";
export const GET_PRODUCT_COUPON_SUCCESS = "GET_PRODUCT_COUPON_SUCCESS";
export const GET_PRODUCT_COUPON_ERROR = "GET_PRODUCT_COUPON_ERROR";

export const GET_COUPONBYCODE = "GET_COUPONBYCODE";
export const GET_COUPONBYCODE_SUCCESS = "GET_COUPONBYCODE_SUCCESS";
export const GET_COUPONBYCODE_ERROR = "GET_COUPONBYCODE_ERROR";

export const POST_ZAAK_PAY = "POST_ZAAK_PAY";
export const POST_ZAAK_PAY_SUCCESS = "POST_ZAAK_PAY_SUCCESS";
export const POST_ZAAK_PAY_ERROR = "POST_ZAAK_PAY_ERROR";

export const REDIRECT_ZAAK_PAY = "REDIRECT_ZAAK_PAY";
export const REDIRECT_ZAAK_PAY_SUCCESS = "REDIRECT_ZAAK_PAY_SUCCESS";
export const REDIRECT_ZAAK_PAY_ERROR = "REDIRECT_ZAAK_PAY_ERROR";

export const RESET_ERRORS = "RESET_ERRORS";
export const SET_SIGNUP_GOOGLEFACEBOOK="SET_SIGNUP_GOOGLEFACEBOOK";

export const GET_COURSE_CONTENT = "GET_COURSE_CONTENT";
export const GET_COURSE_CONTENT_SUCCESS= "GET_COURSE_CONTENT_SUCCESS";
export const GET_COURSE_CONTENT_ERROR = "GET_COURSE_CONTENT_ERROR";


export * from "./settings/actions";
export * from "./student/actions";
export * from "./auth/actions";
export * from "./product/actions"
