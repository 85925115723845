import {
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_DETAILS_SUCCESS,
    GET_PRODUCT_DETAILS_ERROR,
    GET_COUPON_DETAILS,
    GET_COUPON_DETAILS_SUCCESS,
    GET_COUPON_DETAILS_ERROR,
    GET_PRODUCT_COUPON,
    GET_PRODUCT_COUPON_SUCCESS,
    GET_PRODUCT_COUPON_ERROR,
    GET_COUPONBYCODE,
    GET_COUPONBYCODE_SUCCESS,
    GET_COUPONBYCODE_ERROR,
    POST_ZAAK_PAY,
    POST_ZAAK_PAY_SUCCESS,
    POST_ZAAK_PAY_ERROR,
    REDIRECT_ZAAK_PAY,
    REDIRECT_ZAAK_PAY_SUCCESS,
    REDIRECT_ZAAK_PAY_ERROR,
    GET_COURSE_CONTENT,
    GET_COURSE_CONTENT_SUCCESS,
    GET_COURSE_CONTENT_ERROR,
    CUSTOM_ERROR
} from '../actions';

export const getProduct = (user) => ({
    type: GET_PRODUCT_DETAILS,
    payload: { user }
});
export const getProductSuccess = user => ({
    type: GET_PRODUCT_DETAILS_SUCCESS,
    payload: user
});
export const getProductError = message => ({
    type: GET_PRODUCT_DETAILS_ERROR,
    payload: { message }
});
export const getCoupons = (user, history) => ({
    type: GET_COUPON_DETAILS,
    payload: { user, history }
});
export const getCouponsSuccess = user => ({
    type: GET_COUPON_DETAILS_SUCCESS,
    payload: user
});
export const getCouponsError = message => ({
    type: GET_COUPON_DETAILS_ERROR,
    payload: { message }
});
export const getProductCoupons = (user, history) => ({
    type: GET_PRODUCT_COUPON,
    payload: { user, history }
});
export const getProductCouponsSuccess = user => ({
    type: GET_PRODUCT_COUPON_SUCCESS,
    payload: user
});
export const getProductCouponsError = message => ({
    type: GET_PRODUCT_COUPON_ERROR,
    payload: { message }
});
export const getCouponByCode = (user, history) => ({
    type: GET_COUPONBYCODE,
    payload: { user, history }
});
export const getCouponByCodeSuccess = user => ({
    type: GET_COUPONBYCODE_SUCCESS,
    payload: user
});
export const getCouponByCodeError = message => ({
    type: GET_COUPONBYCODE_ERROR,
    payload: { message }
});
export const postToZaakPay = (user, history) => ({
    type: POST_ZAAK_PAY,
    payload: { user, history }
});
export const postToZaakPaySuccess = user => ({
    type: POST_ZAAK_PAY_SUCCESS,
    payload: user
});
export const postToZaakPayError = message => ({
    type: POST_ZAAK_PAY_ERROR,
    payload: { message }
});
export const redirectZaakPayUrl = (params, history) => ({
    type: REDIRECT_ZAAK_PAY,
    payload: { params, history }
});
export const redirectZaakPayUrlSuccess = data => ({
    type: REDIRECT_ZAAK_PAY_SUCCESS,
    payload: data
});
export const redirectZaakPayUrlError = message => ({
    type: REDIRECT_ZAAK_PAY_ERROR,
    payload: { message }
});

export const getCourseContent = data => ({
    type: GET_COURSE_CONTENT,
    payload: data
});
export const getCourseContentSuccess = data => ({
    type: GET_COURSE_CONTENT_SUCCESS,
    payload: data
});
export const getCourseContentError = message => ({
    type: GET_COURSE_CONTENT_ERROR,
    payload: { message }
});