import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import studentSagas from './student/saga';
import productSagas from './product/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    studentSagas(),
    productSagas()
  ]);
}
