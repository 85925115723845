import { combineReducers } from 'redux';
import settings from './settings/reducer';
import authUser from './auth/reducer';
import studentApp from './student/reducer';
import product from './product/reducer';

const reducers = combineReducers({
  settings,
  authUser,
  studentApp,
  product
});

export default reducers;