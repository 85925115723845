/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden menu-hidden menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

// export const firebaseConfig = {
//   apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
//   authDomain: "gogo-react-login.firebaseapp.com",
//   databaseURL: "https://gogo-react-login.firebaseio.com",
//   projectId: "gogo-react-login",
//   storageBucket: "gogo-react-login.appspot.com",
//   messagingSenderId: "216495999563"
// };
export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";
// export const localPath = "https://pat.leverageedu.com/services/account/";
// export const localPath = "http://localhost:8010/";
export const intalkPath = "https://apileverageedu.intalk.io/cc/api/v1/";

export const localPath = process.env.REACT_APP_ACCOUNT_SERVICE;
export const marketplace =process.env.REACT_APP_MARKETPLACE_SERVICE;
export const defaultRedirect = process.env.REACT_APP_DEFAULT_REDIRECT;
export const univalleydefaultRedirect = process.env.REACT_APP_UNIVALLEY_DEFAULT_REDIRECT;
export const uniConnectDefaultRedirect = process.env.REACT_APP_UNICONNECT_DEFAULT_REDIRECT;
export const uniconnectUrl = process.env.REACT_APP_UNICONNECT_URL;
export const SERVICES_URL = process.env.REACT_APP_SERVICES_URL ;
export const studentPortal = process.env.REACT_APP_STUDENT_PORTAL;
export const leverageLive = process.env.REACT_APP_LEVERAGE_LIVE_URL;
export const liveClasses= process.env.REACT_APP_LIVE_CLASSES_URL;

// ******************************************************************************Live****************************************************************************//
// export const localPath = "https://api.leverageedu.com/services/account/";
// export const marketplace = "https://api.leverageedu.com/services/marketplace/";//'http://localhost:4004/'
// export const defaultRedirect = "https://leverageedu.com/app/";
// export const univalleydefaultRedirect = "https://univalley.com/app/";
// export const uniConnectDefaultRedirect = "https://uniconnect.leverageedu.com/"
// export const uniconnectUrl = "https://uniconnect.leverageedu.com/";
// export const SERVICES_URL = 'https://api.leverageedu.com/services/';
// export const studentPortal = "https://api.leverageedu.com/services/ssplp/";
// export const leverageLive = "https://live.leverageedu.com/";
// export const liveClasses= "https://api.leverageedu.com/services/live-classes/";
// ******************************************************************************Pat****************************************************************************//
//  export const localPath = "https://apiuat.leverageedu.com/account/";
// export const marketplace = "https://apiuat.leverageedu.com/marketplace/"
// export const defaultRedirect = "https://pat.leverageedu.com/app/";
// export const univalleydefaultRedirect = "https://pat.univalley.com/app/";
// export const uniConnectDefaultRedirect = "https://uniconnectuat.leverageedu.com/"
// export const uniconnectUrl = "https://uniconnectuat.leverageedu.com/";
// export const SERVICES_URL = 'https://apiuat.leverageedu.com/'
// export const studentPortal = "https://apiuat.leverageedu.com/portal/";
// export const leverageLive = "https://liveuat.leverageedu.com/";
// export const liveClasses = "https://apiuat.leverageedu.com/liveclasses/";
// // ******************************************************************************LocalTesting****************************************************************************//
// export const marketplace = "http://localhost:4002/"


/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color";
export const isMultiColorActive = true;
export const defaultColor = "light.blue";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
export const AES_KEY = "Zr4u7x!A%D*G-KaPdSgUkXp2s5v8y/B?E(H+MbQeThWmYq3t6w9z$C&F)J@NcRfU";
export const toeflProductId = ["1182","1183","1184"];
export const batchEnrolmentProductId=["1183"];
export const recordingEnrolmentProductId=["1182"];
export const defaultTOEFLCouponCode = "LEVTOEFL";
export const TOEFLTestProductID =1184;
export const utmSource = "Facebook_medium=Facebook";

