import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from '../../helpers/Firebase';
import { localPath, marketplace, liveClasses } from "../../constants/defaultValues";
import * as authHelpers from "../../helpers/auth-service";

import {
  GET_PRODUCT_DETAILS,
  getProductSuccess,
  getProductError,
  GET_PRODUCT_COUPON,
  getProductCouponsSuccess,
  getProductCouponsError,
  GET_COUPON_DETAILS,
  getCouponsSuccess,
  getCouponsError,
  GET_COUPONBYCODE,
  getCouponByCodeSuccess,
  getCouponByCodeError,
  POST_ZAAK_PAY,
  postToZaakPaySuccess,
  postToZaakPayError,
  REDIRECT_ZAAK_PAY,
  redirectZaakPayUrlSuccess,
  redirectZaakPayUrlError,
  GET_COURSE_CONTENT,
  getCourseContentSuccess,
  getCourseContentError
} from "../actions";

import axios from "axios";

export function getMarketplaceAsync(payload, authHeader) {

  return new Promise(async (resolve, reject) => {
    try {
      let header = authHeader ? {
        Authorization: authHelpers.getToken()
      } : {};
      const { url, params } = payload;
      const result = await axios.request({
        method: "get",
        url: marketplace + url + params,
        headers: header
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getProductRequest(payload) {

  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "get",
        url: marketplace + `products/${payload.user.id}`
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCourseContentRequest(payload) {

  return new Promise(async (resolve, reject) => {
    try {
      debugger
      const result = await axios.request({
        method: "get",
        url: liveClasses + `course/getCourseContentByProductId?product_id=${payload}`
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getProductCouponRequest(payload) {

  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "get",
        url: marketplace + `coupons/coupon_productId/${payload.user}`
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCouponRequest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "post",
        url: localPath + "student/getStudent",
        data: payload
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCouponDetailRequest(payload) {

  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "post",
        url: marketplace + "coupons/applyCoupon",
        data: payload
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function postPaymentRequest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "post",
        url: localPath + "common/postPayment",
        data: payload
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
function* getProduct({ payload }) {
  try {

    const response = yield call(getProductRequest, payload);
    if (response.meta.code === 200) {
      yield put(getProductSuccess(response.data));
    } else {
      yield put(getProductError(response.meta.message));
    }
  } catch (error) {
    yield put(getProductError(error));
  }
}

function* getCourseContent({ payload }) {
  try {
    const response = yield call(getCourseContentRequest, payload);
    if (response.meta.code === 200) {
      yield put(getCourseContentSuccess(response));
    } else {
      yield put(getCourseContentError(response.meta.message));
    }
  } catch (error) {
    yield put(getCourseContentError(error));
  }
}

function* getCoupons({ payload }) {
  try {
    const response = yield call(getCouponRequest, payload);
    if (response.meta.code === 200) {
      yield put(getCouponsSuccess(response.Data));
    } else {
      yield put(getCouponsError(response.meta.Message));
    }
  } catch (error) {
    yield put(getCouponsError(error));
  }
}

function* getProductCoupons({ payload }) {
  try {
    const response = yield call(getProductCouponRequest, payload);
    if (response.meta.code === 200) {
      yield put(getProductCouponsSuccess(response));
    } else {
      yield put(getProductCouponsError(response.meta.Message));
    }
  } catch (error) {
    yield put(getProductCouponsError(error));
  }
}


function* getCouponDetails({ payload }) {
  try {
    const response = yield call(getCouponDetailRequest, payload.user);

    if (response.meta.code === 200) {
      yield put(getCouponByCodeSuccess(response));
    } else {
      yield put(getCouponByCodeError(response.meta.message));
    }
  } catch (error) {
    yield put(getCouponByCodeError(error));
  }
}
function* postZaakPayment({ payload }) {
  try {
    const response = yield call(postPaymentRequest, payload);
    if (response.meta.code === 200) {
      yield put(postToZaakPaySuccess(response.Data));
    } else {
      yield put(postToZaakPayError(response.meta.Message));
    }
  } catch (error) {
    yield put(postToZaakPayError(error));
  }
}
function* redirectZaakPay({ payload }) {
  try {
    const { params, history } = payload;

    let data = {
      params,
      url: "payment/getTransactionUrl"
    }
    const response = yield call(getMarketplaceAsync, data, true);
    if (response.meta.code === 200) {
      // window.location.assign(response.data);
      yield put(postToZaakPaySuccess(response));
    } else {
      yield put(postToZaakPayError(response.meta.Message));
    }
  } catch (error) {
    yield put(postToZaakPayError(error));
  }
}
export function* watchGetProduct() {
  yield takeEvery(GET_PRODUCT_DETAILS, getProduct);
}
export function* watchGetCourseContent() {
  yield takeEvery(GET_COURSE_CONTENT, getCourseContent);
}
export function* watchGetCoupons() {
  yield takeEvery(GET_COUPON_DETAILS, getCoupons);
}
export function* watchCouponDetail() {
  yield takeEvery(GET_COUPONBYCODE, getCouponDetails);
}
export function* watchZaakPayment() {
  yield takeEvery(POST_ZAAK_PAY, postZaakPayment);
}
export function* watchRedirectZaakPay() {
  yield takeEvery(REDIRECT_ZAAK_PAY, redirectZaakPay);
}
export function* watchProductCoupons() {
  yield takeEvery(GET_PRODUCT_COUPON, getProductCoupons);
}


export default function* rootSaga() {
  yield all([
    fork(watchRedirectZaakPay),
    fork(watchGetCoupons),
    fork(watchCouponDetail),
    fork(watchGetProduct),
    fork(watchZaakPayment),
    fork(watchProductCoupons),
    fork(watchGetCourseContent)
  ]);
}
