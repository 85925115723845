import {
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_DETAILS_SUCCESS,
    GET_PRODUCT_DETAILS_ERROR,
    GET_COUPON_DETAILS,
    GET_COUPON_DETAILS_SUCCESS,
    GET_COUPON_DETAILS_ERROR,
    GET_PRODUCT_COUPON,
    GET_PRODUCT_COUPON_SUCCESS,
    GET_PRODUCT_COUPON_ERROR,
    GET_COUPONBYCODE,
    GET_COUPONBYCODE_SUCCESS,
    GET_COUPONBYCODE_ERROR,
    POST_ZAAK_PAY,
    POST_ZAAK_PAY_SUCCESS,
    POST_ZAAK_PAY_ERROR,
    REDIRECT_ZAAK_PAY,
    REDIRECT_ZAAK_PAY_SUCCESS,
    REDIRECT_ZAAK_PAY_ERROR,
    GET_COURSE_CONTENT,
    GET_COURSE_CONTENT_SUCCESS,
    GET_COURSE_CONTENT_ERROR,
    CUSTOM_ERROR
} from '../actions';

const INIT_STATE = {
    productDetails: [],
    coupon: {},
    productCoupons: [],
    order_created_data: {},
    couponDetails: "",
    paymentSuccess: "",
    success: "",
    error: "",
    coupon_code: "",
    loading: false,
    loadingCoupon: false,
    couponSuccess: false,
    couponFailure: false,
    is_order_created: false,
    couponMessage: "",
    productId: null,
    businessUnit: "",
    courseIndex:"",
    productDetails:false,
    courseDetails:{}
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCT_DETAILS:
            return { ...state, loading: true, error: "",productDetails:true, productId: action.payload.user.id, businessUnit: action.payload.user.business_unit,courseIndex:action.payload.user.course_index };
        case GET_PRODUCT_DETAILS_SUCCESS:

            var product = [];
            product.push(action.payload);
            return {
                ...state,
                loading: false,
                productDetails: product,
            };
        case GET_PRODUCT_DETAILS_ERROR:

            return { ...state, loading: false, error: action.payload };
        case GET_COUPON_DETAILS:
            return { ...state, loading: true, error: "" };
        case GET_COUPON_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                coupons: action.payload,
                success: action.payload.meta.Message
            };
        case GET_COUPON_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload };

        case GET_PRODUCT_COUPON:
            return { ...state, loading: true, error: "" };
        case GET_PRODUCT_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                productCoupons: action.payload.data,
                success: action.payload.meta.Message
            };
        case GET_PRODUCT_COUPON_ERROR:
            return { ...state, loading: false, error: action.payload };


        case GET_COUPONBYCODE:

            return { ...state, loadingCoupon: true, error: "" };
        case GET_COUPONBYCODE_SUCCESS:

            return {
                ...state,
                loadingCoupon: false,
                couponMessage: action.payload.meta.message,
                coupon_code: action.payload.data.coupon.coupon_code,
                coupon: action.payload.data.coupon,
                couponSuccess: true,
                couponFailure: false
            };
        case GET_COUPONBYCODE_ERROR:

            return {
                ...state,
                loadingCoupon: false,
                coupon_code: "",
                couponFailure: true,
                coupon: {},
                couponMessage: action.payload,
                couponSuccess: false
            };
        case POST_ZAAK_PAY:
            return { ...state, loading: true, error: "" };
        case POST_ZAAK_PAY_SUCCESS:
            return {
                ...state,
                loading: false,
                is_order_created: true,
                order_created_data: action.payload.data,
                paymentSuccess: action.payload,
                success: action.payload.meta.message
            };
        case POST_ZAAK_PAY_ERROR:
            return { ...state, loading: false, error: action.payload };
        case REDIRECT_ZAAK_PAY:
            return { ...state, loading: true, error: "" };
        case REDIRECT_ZAAK_PAY_SUCCESS:
            return {
                ...state,
                loading: false,
                // paymentSuccess: action.payload,
                success: action.payload.meta.Message
            };
        case REDIRECT_ZAAK_PAY_ERROR:
            return { ...state, loading: false, error: action.payload };
        case GET_COURSE_CONTENT:
                return { ...state, loading: true, error: "" };
        case GET_COURSE_CONTENT_SUCCESS:
            debugger
                return {
                    ...state,
                    loading: false,
                    courseDetails:  action.payload.data,
                    success: action.payload.meta.Message
                };
            case GET_COURSE_CONTENT_ERROR:
                return { ...state, loading: false, error: action.payload };    
        case CUSTOM_ERROR:
            return { ...state, loading: false, error: action.payload };
        default:
            return { ...state };
    }
};