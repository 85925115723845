import {
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_STUDENT_DETAILS_ERROR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_PAYMENT_SOURCES,
  GET_PAYMENT_SOURCES_SUCCESS,
  GET_PAYMENT_SOURCES_ERROR,
  CUSTOM_ERROR
} from "../actions";

const INIT_STATE = {
  loading: false,
  loadingStudentDetails: false,
  loadingStudentList: false,
  loadingAddNotes: false,
  studentSubmitSuccess: "",
  updateStudentExperienceSuccess: "",
  submitSchoolFitmentSuccess: "",
  submitAppSuccess: "",
  addEnrolmentSuccess: "",
  submitSuccess: "",
  scheduleCallSuccess: "",
  alignMentorSuccess: "",
  allProducts: [],
  studentExperiences: [],
  stageHistory: [],
  allStudents: [],
  studentList: [],
  paymentSources: [],
  backendUsers: [],
  scoresMaster: [],
  studentDetails: {},
  schoolsMaster: [],
  schoolAddSuccess: "",
  cases: [],
  caseAddSuccess: "",
  countryMaster: [],
  headCoachesList: [],
  alignHeadCoachSuccess: "",
  KAMList: [],
  salesPOCList: [],
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  submitSuccessNotes: "",
  submitaddfitmentSuccess: "",
  updateStudentSuccess: "",
  errorUpdate: "",
  selectedItems: [],
  essayVersionData: [],
  mentorsData: [],
  mentorsDetails: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDENT_DETAILS:
      return { ...state, loadingStudentDetails: true, error: "" };

    case GET_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        loadingStudentDetails: false,
        studentDetails: action.payload
      };
    case GET_STUDENT_DETAILS_ERROR:
      return { ...state, loadingStudentDetails: false, error: action.payload };

    case GET_PRODUCT_LIST:
      return { ...state, loading: true, error: "" };

    case GET_PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, allProducts: action.payload };

    case GET_PRODUCT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case GET_PAYMENT_SOURCES:
      return { ...state, loading: true, error: "" };

    case GET_PAYMENT_SOURCES_SUCCESS:
      return { ...state, loading: false, paymentSources: action.payload };

    case GET_PAYMENT_SOURCES_ERROR:
      return { ...state, loading: false, error: action.payload };
    case CUSTOM_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
