import {
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_STUDENT_DETAILS_ERROR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_PAYMENT_SOURCES,
  GET_PAYMENT_SOURCES_SUCCESS,
  GET_PAYMENT_SOURCES_ERROR,
} from "../actions";

export const getStudentDetails = payload => ({
  type: GET_STUDENT_DETAILS,
  payload: payload
});
export const getStudentDetailsSuccess = items => ({
  type: GET_STUDENT_DETAILS_SUCCESS,
  payload: items
});

export const getStudentDetailsError = error => ({
  type: GET_STUDENT_DETAILS_ERROR,
  payload: error
});
export const getProductList = () => ({
  type: GET_PRODUCT_LIST
});
export const getProductListSuccess = items => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload: items
});

export const getProductListError = error => ({
  type: GET_PRODUCT_LIST_ERROR,
  payload: error
});
export const getPaymentSources = () => ({
  type: GET_PAYMENT_SOURCES
});
export const getPaymentSourcesSuccess = items => ({
  type: GET_PAYMENT_SOURCES_SUCCESS,
  payload: items
});

export const getPaymentSourcesError = error => ({
  type: GET_PAYMENT_SOURCES_ERROR,
  payload: error
});