import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from '../../helpers/Firebase';
import { localPath } from "../../constants/defaultValues";

import {
  getStudentDetailsSuccess,
  getStudentDetailsError,
  GET_STUDENT_DETAILS,
  GET_PRODUCT_LIST,
  getProductListSuccess,
  getProductListError,
  GET_PAYMENT_SOURCES,
  getPaymentSourcesSuccess,
  getPaymentSourcesError,
} from "../actions";

import axios from "axios";
import { addMentorSuccess, addMentorError, submitPsychometricAnalysisMainSuccess, submitPsychometricAnalysisMainError, getMentorSuccess, getMentorError, scheduleCallSuccess, scheduleCallError } from "./actions";

// function loginWithEmailPasswordAsync(payload) {
//     return axios.request({
//         method: 'post',
//         url: 'http://localhost:8010/api/account/login',
//         data: payload
//     });
// }

export function getStudentDetailsRequest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "post",
        url: localPath + "student/getStudent",
        data: payload
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getProductListRequest() {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "post",
        url: localPath + "product/getProducts"
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getPaymentSourcesRequest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.request({
        method: "post",
        url: localPath + "common/getPaymentSources",
        data: payload
      });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
}
function* getProductListItems({ payload }) {
  try {
    const response = yield call(getProductListRequest, payload);
    if (response.meta.code === 200) {
      yield put(getProductListSuccess(response.Data));
    } else {
      yield put(getProductListError(response.meta.Message));
    }
  } catch (error) {
    yield put(getProductListError(error));
  }
}
function* getStudentDetails({ payload }) {
  try {
    const response = yield call(getStudentDetailsRequest, payload);
    if (response.meta.code === 200) {
      yield put(getStudentDetailsSuccess(response.Data));
    } else {
      yield put(getStudentDetailsError(response.meta.Message));
    }
  } catch (error) {
    yield put(getStudentDetailsError(error));
  }
}
function* getPaymentSources({ payload }) {
  try {
    const response = yield call(getPaymentSourcesRequest, payload);
    if (response.meta.code === 200) {
      yield put(getPaymentSourcesSuccess(response.Data));
    } else {
      yield put(getPaymentSourcesError(response.meta.Message));
    }
  } catch (error) {
    yield put(getPaymentSourcesError(error));
  }
}
export function* watchGetProductList() {
  yield takeEvery(GET_PRODUCT_LIST, getProductListItems);
}
export function* watchGetDetails() {
  yield takeEvery(GET_STUDENT_DETAILS, getStudentDetails);
}
export function* watchGetPaymentSources() {
  yield takeEvery(GET_PAYMENT_SOURCES, getPaymentSources);
}


export default function* rootSaga() {
  yield all([
    fork(watchGetDetails),
    fork(watchGetProductList),
    fork(watchGetPaymentSources)
  ]);
}
